import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import Image1 from "../../../../assets/images/operating-manual/appendix/appendix_six.png";

const AppendixASymbolGlossary = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Typography variant="h6" component="h1" gutterBottom>
          Appendix A - Symbol Glossary
        </Typography>
        <Box
          component="img"
          src={Image1}
          alt="Appendix a"
          sx={{
            width: "100%",
            maxWidth: "50%",
            height: "auto",
            borderRadius: 1,
            display: "block",
          }}
        />
      </Box>
    </>
  );
};

export default AppendixASymbolGlossary;
