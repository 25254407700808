import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import Image1 from "../../../../../assets/images/operating-manual/5/5.1g.png";

const VRDeviceCare = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <div id="vr-device-care">
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
            color: "white",
            p: 1,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          {" "}
          <Typography variant="h6" fontWeight={"bold"} color={"white"}>
            {t("operating_manual_vr_device_care_title")}
          </Typography>
        </Box>

        {[...Array(3)].map((_, index) => (
          <Fragment key={index}>
            <Typography variant="h5" fontWeight="bold">
              {t(`operating_manual_vr_device_care_header${index + 1}`)}
            </Typography>
            {index !== 1 ? (
              <Typography>
                {t(`operating_manual_vr_device_care_content${index + 1}`)}
              </Typography>
            ) : (
              <Box display="flex" alignItems="start" sx={{ gap: 3 }}>
                <Box flex="1"></Box>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingInlineStart: "16px",
                    margin: 0,
                  }}
                >
                  {[...Array(4)].map((_, innerIndex) => (
                    <li key={innerIndex}>
                      {t(
                        `operating_manual_vr_device_care_content${index + 1}_${innerIndex + 1}`
                      )}
                    </li>
                  ))}
                </ul>
                <Box
                  component="img"
                  src={Image1}
                  alt="Device Care Image"
                  sx={{
                    width: "100%",
                    maxWidth: "200px",
                    borderRadius: 1,
                    display: "block",
                    alignSelf: "start",
                  }}
                />
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    </div>
  );
};

export default VRDeviceCare;
