import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Description2 from "../../../../../assets/images/operating-manual/4/description4.3a.png";
import Description3 from "../../../../../assets/images/operating-manual/4/description4.3b.png";
import Description4 from "../../../../../assets/images/operating-manual/4/description4.3c.png";
import Description5 from "../../../../../assets/images/operating-manual/4/description4.3d.png";

const TurningOnVRDevice = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box
      id="turning-on-vr-device"
      sx={{ ...sectionContainerStyle, mt: 3, padding: { xs: 2, sm: 3 } }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          flexDirection: { xs: "column", sm: "row" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.3. {t("operating_manual_turning_on_vr_device_title")}
        </Typography>
      </Box>

      <Box component="ol" sx={{ pl: { xs: 2, sm: 3 } }}>
        <li>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <Typography variant="body1">
              {t("operating_manual_turning_on_vr_device_note_description1_a")}
            </Typography>
            <Box
              component="img"
              src={Description2}
              alt="Description 3"
              sx={{
                mx: 1,
                maxWidth: { xs: "10%", sm: "5%" },
                height: "auto",
              }}
            />
            <Typography variant="body1">
              {t("operating_manual_turning_on_vr_device_note_description1_b")}
            </Typography>
            <Box
              component="img"
              src={Description5}
              alt="Description 4"
              sx={{
                mx: 1,
                maxWidth: { xs: "10%", sm: "5%" },
                height: "auto",
              }}
            />
          </Box>
          <Box
            component="img"
            src={Description3}
            alt="Description 3"
            sx={{
              mt: 2,
              mb: 2,
              maxWidth: { xs: "80%", sm: "30%" },
              width: "100%",
              flex: "1 1 100%",
              height: "auto",
            }}
          />
        </li>

        <li>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <Typography variant="body1">
              {t("operating_manual_turning_on_vr_device_note_description2_a")}
            </Typography>
            <Box
              component="img"
              src={Description2}
              alt="Description 3"
              sx={{
                mx: 1,
                maxWidth: { xs: "10%", sm: "5%" },
                height: "auto",
              }}
            />
            <Typography variant="body1">
              {t("operating_manual_turning_on_vr_device_note_description2_b")}
            </Typography>
            <Box
              component="img"
              src={Description5}
              alt="Description 4"
              sx={{
                mx: 1,
                maxWidth: { xs: "10%", sm: "5%" },
                height: "auto",
              }}
            />
          </Box>
        </li>

        <Box
          component="img"
          src={Description4}
          alt="Description 3"
          sx={{
            mt: 2,
            mb: 2,
            maxWidth: { xs: "80%", sm: "30%" },
            width: "100%",
            flex: "1 1 100%",
            height: "auto",
          }}
        />
      </Box>
      <Box
        sx={{
          p: 2,
          mt: 2,
          backgroundColor: "grey.100",
          color: "text.secondary",
          borderRadius: 1,
        }}
      >
        {t("operating_manual_turning_on_vr_device_note")}
      </Box>
    </Box>
  );
};

export default TurningOnVRDevice;
