import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import Headset1 from "../../../../../assets/images/operating-manual/head-mounted-device/headset1.png";
import Headset2 from "../../../../../assets/images/operating-manual/head-mounted-device/headset2.png";
import Controller from "../../../../../assets/images/operating-manual/head-mounted-device/controller.png";
import PowerButton from "../../../../../assets/images/operating-manual/head-mounted-device/headset/power-button.png";
import UsbcPort from "../../../../../assets/images/operating-manual/head-mounted-device/headset/usbc-port.png";
import Volume from "../../../../../assets/images/operating-manual/head-mounted-device/headset/volume.png";
import Select from "../../../../../assets/images/operating-manual/head-mounted-device/controller/select-button.png";
import Pause from "../../../../../assets/images/operating-manual/head-mounted-device/controller/pause-button.png";
import Recenter from "../../../../../assets/images/operating-manual/head-mounted-device/controller/recenter-button.png";
import AdminSettings from "../../../../../assets/images/operating-manual/head-mounted-device/controller/power-admin-settings-button.png";
import Trigger from "../../../../../assets/images/operating-manual/head-mounted-device/controller/trigger.png";

const HeadMountedDeviceGuide = () => {
  const { t } = useTranslation();
  const imgAttributes = [
    [
      { src: PowerButton, alt: "power-button" },
      { src: UsbcPort, alt: "usbc-port" },
      { src: Volume, alt: "volume" },
    ],
    [
      { src: Select, alt: "select-button" },
      { src: Pause, alt: "pause-button" },
      { src: Recenter, alt: "recenter-button" },
      { src: AdminSettings, alt: "admin-settings-button" },
      { src: Trigger, alt: "trigger-button" },
    ],
  ];
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box
      id="head-mounted-device-guide"
      sx={{ ...sectionContainerStyle, padding: 3 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" gutterBottom fontWeight={"bold"}>
          3.3 {t("operating_manual_head_mounted_device_guide_title")}
        </Typography>
      </Box>
      <Grid container display={"flex"}>
        <img
          src={Headset1}
          width={"30%"}
          style={{ objectFit: "cover" }}
          alt="headset1"
        />
        <img src={Headset2} width={"70%"} alt="headset2" />
      </Grid>

      {/* Headset Controls */}
      <div
        className="mx-auto my-5 bg-gray-100 p-4 rounded-lg shadow-md"
        id="test-patterns"
      >
        {[...Array(3)].map((_, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={index}
            className="mb-4"
          >
            <Grid item xs={1}>
              <Typography fontWeight="bold">{index + 1}</Typography>
            </Grid>
            <Grid item xs={2}>
              <img
                src={imgAttributes[0][index].src}
                alt={imgAttributes[0][index].alt}
                style={{ width: "80%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography fontWeight="bold">
                {t(
                  `operating_manual_head_mounted_device_guide_headset${index + 1}_header`
                )}
              </Typography>
              <Typography>
                {t(
                  `operating_manual_head_mounted_device_guide_headset${index + 1}_content`
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>

      {/* Status Indicators */}
      <Typography gutterBottom fontWeight="bold" className="mt-5">
        {t("operating_manual_head_mounted_device_guide_status_indicators")}
      </Typography>
      <Grid
        container
        className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
      >
        {[...Array(7)].map((_, index) => (
          <Grid
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            key={index}
            className="mb-4"
          >
            <Grid item xs={4}>
              <Typography fontWeight="bold">
                {t(
                  `operating_manual_head_mounted_device_guide_status_indicators${index + 1}_header`
                )}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {t(
                  `operating_manual_head_mounted_device_guide_status_indicators${index + 1}_content`
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Controller Section */}
      <Grid container justifyContent="center" className="my-4">
        <img
          src={Controller}
          alt="controller"
          style={{ maxWidth: "100%", borderRadius: "8px" }}
        />
      </Grid>

      {/* Controller Controls */}
      <div className="mx-auto my-5 bg-gray-100 p-4 rounded-lg shadow-md">
        {[...Array(5)].map((_, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={index}
            className="mb-4"
          >
            <Grid item xs={1}>
              <Typography fontWeight="bold">{index + 1}</Typography>
            </Grid>
            <Grid item xs={2}>
              <img
                src={imgAttributes[1][index].src}
                alt={imgAttributes[1][index].alt}
                style={{ width: "80%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography fontWeight="bold">
                {t(
                  `operating_manual_head_mounted_device_guide_controller${index + 1}_header`
                )}
              </Typography>
              <Typography>
                {t(
                  `operating_manual_head_mounted_device_guide_controller${index + 1}_content`
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    </Box>
  );
};

export default HeadMountedDeviceGuide;
