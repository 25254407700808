import { createContext } from "react";

const OperatingManualStyleContext = createContext({
  sectionContainerStyle: {},
});

const OperatingManualStyleProvider = ({ children }) => {
  return (
    <OperatingManualStyleContext.Provider
      value={{ sectionContainerStyle: { margin: "20px 0px 20px 0px" } }}
    >
      {children}
    </OperatingManualStyleContext.Provider>
  );
};

export { OperatingManualStyleContext, OperatingManualStyleProvider };
