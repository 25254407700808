import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Description2 from "../../../../../assets/images/operating-manual/4/description4.4a.png";
import Description3 from "../../../../../assets/images/operating-manual/4/description4.4b.png";
import CircularPlusIcon from "./CircularPlus";

const CreatePatient = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box
      id="create-select-patient"
      sx={{ ...sectionContainerStyle, padding: 3 }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.4. {t("operating_manual_select_patient_title")}
        </Typography>
      </Box>

      <Box component="ol" sx={{ pl: 3 }}>
        {/* Step 1 */}
        <li>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" component="span">
              {t("operating_manual_select_patient_description1")}{" "}
              <u>{t("operating_manual_select_patient_description1_a")}</u>
            </Typography>
          </Box>
          <Box
            component="img"
            src={Description2}
            alt="Description 2"
            sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
          />
        </li>

        <li>
          <Typography variant="body1" component="span">
            {t("operating_manual_select_patient_description2")}{" "}
            <CircularPlusIcon />
            {t("operating_manual_select_patient_description2_a")}
          </Typography>

          <Typography variant="body1" component="p">
            {t("operating_manual_select_patient_description2_b")}
          </Typography>
          <Box
            component="img"
            src={Description3}
            alt="Description 3"
            sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
          />
        </li>
      </Box>
      <Box
        sx={{
          p: 2,
          mt: 2,
          backgroundColor: "grey.100",
          color: "text.secondary",
          borderRadius: 1,
        }}
      >
        {t("operating_manual_select_patient_note")}
      </Box>
    </Box>
  );
};

export default CreatePatient;
