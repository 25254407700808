import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const ContactUs = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        9. Contact Us
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="body1" gutterBottom>
          For technical assistance, comments or complaints, please contact{" "}
          <Typography component="span" color="primary">
            support@retinalogik.ca
          </Typography>{" "}
          or call{" "}
          <Typography component="span" color="primary">
            +1 (587) 905-5689
          </Typography>
          .
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
            color: "white",
            p: 1,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Legal Manufacturer:
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          RetinaLogik Inc.
        </Typography>
        <Typography variant="body2">3655 36 street NW</Typography>
        <Typography variant="body2">Calgary, Alberta, T2L 1Y8</Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "primary.dark",
            color: "white",
            p: 1,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            About the Company:
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          RetinaLogik Inc. is an award-winning company based out of Calgary,
          Alberta, Canada. RetinaLogik’s team is working with top Albertan
          vision specialists to revolutionize vision testing technology.
        </Typography>
        <Typography variant="body1">
          Their mission is to prevent vision loss for millions by making smarter
          vision screening more accessible for everyone, everywhere.
        </Typography>
      </Box>

      <Typography variant="body1">
        For more information, visit{" "}
        <Typography
          component="a"
          href="https://www.retinalogik.ca"
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.retinalogik.ca
        </Typography>
        .
      </Typography>
    </Box>
  );
};

export default ContactUs;
