import { Box, Typography } from "@mui/material";
import AppendixVisualField from "./AppendixVisualField";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const AppendixBPatientView = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Appendix B - Patient View
        </Typography>
        <AppendixVisualField />
      </Box>
    </>
  );
};

export default AppendixBPatientView;
