// Library methods
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";

// Components
import App from "./components/Routing/App";

// Providers
import ThemeProviderWrapper from "./styles/ThemeProviderWrapper";
import { WindowDimensionsProvider } from "./contexts/WindowSizeContext";

// Configs
import reportWebVitals from "./config/reportWebVitals";
import "./i18n";

// Styles
import "./styles/tailwind/index.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProviderWrapper>
        <WindowDimensionsProvider>
          <App />
        </WindowDimensionsProvider>
      </ThemeProviderWrapper>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
