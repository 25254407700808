import { Box } from "@mui/material";
import Image1 from "../../../../../assets/images/operating-manual/appendix/appendix_one.png";
import Image2 from "../../../../../assets/images/operating-manual/appendix/appendix_two.png";
import Image3 from "../../../../../assets/images/operating-manual/appendix/appendix_three.png";
import Image4 from "../../../../../assets/images/operating-manual/appendix/appendix_four.png";

const AppendixVisualField = () => {
  return (
    <div id="appendix-visual-field">
      <Box
        component="img"
        src={Image1}
        alt="Appendix b"
        sx={{
          width: "100%",
          maxWidth: "80%",
          height: "auto",
          borderRadius: 1,
          display: "block",
          margin: "0 auto",
        }}
      />

      <Box
        component="img"
        src={Image2}
        alt="Appendix b"
        sx={{
          width: "100%",
          maxWidth: "80%",
          height: "auto",
          borderRadius: 1,
          mt: 3,
          margin: "0 auto",
          display: "block",
        }}
      />
      <Box
        component="img"
        src={Image3}
        alt="Appendix b"
        sx={{
          width: "100%",
          maxWidth: "80%",
          height: "auto",
          borderRadius: 1,
          mt: 3,
          margin: "0 auto",
          display: "block",
        }}
      />
      <Box
        component="img"
        src={Image4}
        alt="Appendix b"
        sx={{
          width: "100%",
          maxWidth: "50%",
          margin: "0 auto",
          height: "auto",
          borderRadius: 1,
          mt: 3,
          display: "block",
        }}
      />
    </div>
  );
};

export default AppendixVisualField;
