import { Box, Typography } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import Image1 from "../../../../assets/images/operating-manual/appendix/appendix_five.png";

const AppendixCPortalIconGlossary = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Appendix C - Portal Icon Glossary
        </Typography>
        <Box
          component="img"
          src={Image1}
          alt="Appendix c"
          sx={{
            width: "100%",
            maxWidth: "50%",
            height: "auto",
            borderRadius: 1,
            margin: "0 auto",
            mt: 3,
            display: "block",
          }}
        />
      </Box>
    </>
  );
};

export default AppendixCPortalIconGlossary;
