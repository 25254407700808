import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Description1 from "../../../../../assets/images/operating-manual/4/description4.5.png";
import Description2 from "../../../../../assets/images/operating-manual/4/4.5b.png";
import Description3 from "../../../../../assets/images/operating-manual/4/4.5c.png";
import Description4 from "../../../../../assets/images/operating-manual/4/4.5d.png";
import Description5 from "../../../../../assets/images/operating-manual/4/4.5f.png";
import Description6 from "../../../../../assets/images/operating-manual/4/4.5g.png";

import { FaDiscord } from "react-icons/fa";

const CreatingExam = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box id="creating-exam" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.5. {t("operating_manual_creating_exam_title")}
        </Typography>
      </Box>

      {/* <Box component="ol" sx={{ pl: 3 }}>
        <li>
          {t("operating_manual_creating_exam_description1")}{" "}
          <MuiLink component={Link} to="http://www.portal.retinalogik.ca/" target="_blank">
            www.portal.retinalogik.ca
          </MuiLink>
        </li>
        
        <li>{t("operating_manual_creating_exam_description2")}</li>
        
        <li>
          {t("operating_manual_creating_exam_description3")}{" "}
          <span style={{ textDecoration: "underline" }}>
            {t("operating_manual_creating_exam_description4")}
          </span>
        </li>
        
        <li>
          {t("operating_manual_creating_exam_description5")}
          <Box sx={{ p: 2, mt: 1, backgroundColor: "grey.100", color: "text.secondary", borderRadius: 1 }}>
            {t("operating_manual_creating_exam_note1")}
          </Box>
        </li>
        
        <li>{t("operating_manual_creating_exam_description6")}</li>
        <li>{t("operating_manual_creating_exam_description7")}</li>

        <Box sx={{ p: 2, mt: 2, backgroundColor: "grey.100", color: "text.secondary", borderRadius: 1 }}>
          {t("operating_manual_creating_exam_note2")}
        </Box>

        <li>
          {t("operating_manual_creating_exam_description8")}
          <Box sx={{ p: 2, mt: 1, backgroundColor: "grey.100", color: "text.secondary", borderRadius: 1 }}>
            {isEn ? (
              <Typography>
                {t("operating_manual_creating_exam_note3_1")}{" "}
                <span style={{ textDecoration: "underline" }}>
                  {t("operating_manual_creating_exam_note3_2")}
                </span>{" "}
                {t("operating_manual_creating_exam_note3_3")}{" "}
                <span style={{ textDecoration: "underline" }}>
                  {t("operating_manual_creating_exam_note3_4")}
                </span>
              </Typography>
            ) : (
              <Typography>
                {t("operating_manual_creating_exam_note3")}
              </Typography>
            )}
          </Box>
        </li>

        <li>{t("operating_manual_creating_exam_description9")}</li>
        
        <li>
          {t("operating_manual_creating_exam_description10")}
          <Box sx={{ p: 2, mt: 1, backgroundColor: "grey.100", color: "text.secondary", borderRadius: 1 }}>
            {t("operating_manual_creating_exam_note4")}
          </Box>
        </li>
      </Box> */}
      <Box component="ol" sx={{ pl: 3 }}>
        <li>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {t("operating_manual_creating_exam_description6")}
          </Box>
        </li>
        <li>
          {t("operating_manual_creating_exam_description7")}{" "}
          <Box
            component="img"
            src={Description1}
            alt="Description 2"
            sx={{ mt: 2, mb: 2, maxWidth: "30%" }}
          />
        </li>

        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "grey.100",
            color: "text.secondary",
            borderRadius: 1,
          }}
        >
          {t("operating_manual_creating_exam_note3")}
        </Box>
        <li>
          {t("operating_manual_creating_exam_description8")}
          <Box
            component="img"
            src={Description2}
            alt="Description 3"
            sx={{ mt: 2, mb: 2, maxWidth: "30%" }}
          />
        </li>

        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "grey.100",
            color: "text.secondary",
            borderRadius: 1,
          }}
        >
          {t("operating_manual_creating_exam_note3_1")}
          <u>{t("operating_manual_creating_exam_note3_2")}</u>
          {t("operating_manual_creating_exam_note3_3")}
          <u>{t("operating_manual_creating_exam_note3_4")}</u>
        </Box>
        <ol>
          <Box
            component="li"
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <span>
              {t("operating_manual_creating_exam_description9")}{" "}
              <FaDiscord style={{ verticalAlign: "middle", margin: "0 8px" }} />
              {t("operating_manual_creating_exam_description11")}
            </span>
            <Box
              component="img"
              src={Description3}
              alt="Description 3"
              sx={{
                float: "right",
                ml: 2,
                mt: 1,
                maxWidth: { xs: "60%", sm: "30%", md: "20%" },
                width: "100%", // Responsive within maxWidth
                borderRadius: 1,
                display: "block",
              }}
            />
          </Box>

          <Box
            component="li"
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <span>
              {t("operating_manual_creating_exam_description12")}{" "}
              {t("operating_manual_creating_exam_description10")}
            </span>
            <Box
              component="img"
              src={Description4}
              alt="Description 4"
              sx={{
                float: "right",
                ml: 2,
                mt: 1,
                maxWidth: { xs: "60%", sm: "30%", md: "20%" },
                width: "100%", // Responsive within maxWidth
                borderRadius: 1,
                display: "block",
              }}
            />
          </Box>
        </ol>

        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "grey.100",
            color: "text.secondary",
            borderRadius: 1,
            clear: "both",
            position: "relative",
          }}
        >
          <Box
            component="img"
            src={Description5}
            alt="Note Image"
            sx={{
              float: "right",
              ml: 2,
              mb: 1,
              maxWidth: { xs: "40%", sm: "40%", md: "30%" },
              width: "100%",
              borderRadius: 1,
            }}
          />
          <Typography variant="body1">
            {t("operating_manual_creating_exam_note4")}
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ mt: 5 }}>
          {t("operating_manual_creating_exam_description13")}
        </Typography>
        <Box component="ol" sx={{ pl: 3 }}>
          <li>
            {t("operating_manual_creating_exam_description6")}{" "}
            <Box
              component="img"
              src={Description1}
              alt="Description 2"
              sx={{
                float: "right",
                ml: 2,
                mt: 1,
                maxWidth: { xs: "60%", sm: "30%", md: "50%" },
                width: "100%",
                borderRadius: 1,
                display: "block",
              }}
            />
          </li>
          <Box
            component="img"
            src={Description6}
            alt="Description 3"
            sx={{ mt: 2, mb: 2, maxWidth: "20%" }}
          />
          <p>{t("operating_manual_creating_exam_description14")}</p>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatingExam;
