import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import Image1 from "../../../../../assets/images/operating-manual/3/patient-dashboard.png";
import Image2 from "../../../../../assets/images/operating-manual/3/dashboard.png";
import Image3 from "../../../../../assets/images/operating-manual/3/live-updates.png";
import { useTranslation } from "react-i18next";

const PortalGuide = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const { t } = useTranslation();

  return (
    <div id="portal-guide">
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
            color: "white",
            p: 2,
            borderRadius: 2,
            mb: 3,
            boxShadow: "0px 0.625rem 3rem rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" gutterBottom fontWeight="bold">
            3.4 Portal Guide
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {/* Patient Page Section */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Patient Page
            </Typography>
            <Box
              component="img"
              src={Image1}
              alt="Patient Dashboard"
              sx={{
                width: "100%",
                borderRadius: 1,
                display: "block",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
            >
              {[...Array(4)].map((_, index) => (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  key={index}
                  className="mb-4"
                >
                  <Grid item xs={4}>
                    <Typography fontWeight="bold">{index + 1}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography fontWeight="bold">
                      {t(
                        `operating_manual_portal_patient_view_header${index + 1}_header`
                      )}
                    </Typography>
                    <Typography>
                      {t(
                        `operating_manual_portal_patient_view_content${index + 1}_content`
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Dashboard Page
            </Typography>
            <Box
              component="img"
              src={Image2}
              alt="Dashboard"
              sx={{
                width: "100%",
                borderRadius: 1,
                display: "block",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
            >
              {[...Array(5)].map((_, index) => (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  key={index}
                  className="mb-4"
                >
                  <Grid item xs={4}>
                    <Typography fontWeight="bold">{index + 1}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography fontWeight="bold">
                      {t(
                        `operating_manual_portal_dashboard_view_header${index + 1}_header`
                      )}
                    </Typography>
                    <Typography>
                      {t(
                        `operating_manual_portal_dashboard_view_content${index + 1}_content`
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              {t(`operating_manual_portal_live_update_header`)}
            </Typography>
            <Typography>
              {t(`operating_manual_portal_live_update_description1`)}
            </Typography>
            <Box
              component="img"
              src={Image3}
              alt="Live Updates"
              sx={{
                width: "120%",
                borderRadius: 1,
                display: "block",
                mt: 2,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PortalGuide;
