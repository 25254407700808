import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="absolute left-16 inline-block text-left">
      <select
        value={i18n.language}
        onChange={(e) => changeLanguage(e.target.value)}
        className="block w-full px-4 py-2 text-sm font-semibold text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 hover:bg-gray-50 transition-all"
      >
        <option
          value="en-US"
          className="px-4 py-2 text-gray-700 hover:bg-gray-100"
        >
          English
        </option>
        <option
          value="fr"
          className="px-4 py-2 text-gray-700 hover:bg-gray-100"
        >
          Français
        </option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
