import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import Image1 from "../../../../../assets/images/operating-manual/5/5.1a.png";
import Image2 from "../../../../../assets/images/operating-manual/5/5.1b.png";
import Image3 from "../../../../../assets/images/operating-manual/5/5.1c.png";
import Image4 from "../../../../../assets/images/operating-manual/5/5.1d.png";
import Image5 from "../../../../../assets/images/operating-manual/5/5.1e.png";
import Image6 from "../../../../../assets/images/operating-manual/5/5.1f.png";

const SetUpWiFi = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <div id="set-up-wifi">
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
            color: "white",
            p: 1,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" fontWeight={"bold"} color={"white"}>
            5.1 {t("operating_manual_set_up_wifi_title")}
          </Typography>
        </Box>
        <ol>
          <li>{t("operating_manual_set_up_wifi_description1")}</li>
          <li>{t("operating_manual_set_up_wifi_description2")}</li>
          {[...Array(6)].map((_, index) => (
            <li key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    {t(`operating_manual_set_up_wifi_description${index + 3}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    component="img"
                    src={images[index]}
                    alt={`Image ${index + 1}`}
                    sx={{
                      width: "70%",
                      borderRadius: 1,
                      display: "block",
                      ml: "auto",
                    }}
                  />
                </Grid>
              </Grid>
            </li>
          ))}
        </ol>
      </Box>
    </div>
  );
};

export default SetUpWiFi;
