import {
  Grid,
  Typography,
  Box,
  Link as MuiLink,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useTranslation } from "react-i18next";
import Image1 from "../../../../../assets/images/operating-manual/4/4.6a.png";
import Image2 from "../../../../../assets/images/operating-manual/4/4.6b.png";
import Image3 from "../../../../../assets/images/operating-manual/4/4.6c.png";
import recenter from "../../../../../assets/images/operating-manual/4/recenter.png";
import Image4_a from "../../../../../assets/images/operating-manual/4/patient_action_1.png";
import Image4_b from "../../../../../assets/images/operating-manual/4/patient_view_1.png";
import Image5_a from "../../../../../assets/images/operating-manual/4/patient_action_1.png";
import Image5_b from "../../../../../assets/images/operating-manual/4/patient_view_1.png";

const LaunchingExam = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  const onClickUrl = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const items = [
    {
      id: 1,
      description: "operating_manual_launching_exam_description1",
      image: Image1,
    },
    {
      id: 2,
      description: "operating_manual_launching_exam_description2",
      image: Image2,
    },
    {
      id: 3,
      description: "operating_manual_launching_exam_description3",
      image: Image3,
    },
  ];

  return (
    <Box
      id="launching-exam"
      sx={{ ...sectionContainerStyle, mt: 3, padding: 3 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.6. {t("operating_manual_launching_exam_title")}
        </Typography>
      </Box>

      <Typography mb={2}>
        {t("operating_manual_launching_exam_description")}{" "}
        <MuiLink
          component={Link}
          to="/operating-manual/appendix-b-patient-view/"
          onClick={onClickUrl}
          color="primary"
        >
          {t("operating_manual_launching_exam_description_link")}
        </MuiLink>
        .
      </Typography>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        {items.map((item) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={item.id}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              component="img"
              src={item.image}
              alt={`Description ${item.id}`}
              sx={{
                width: "100%",
                maxWidth: "150px",
                borderRadius: 1,
                mb: 1,
              }}
            />
            <Typography variant="h6" fontWeight="bold">
              {item.id}.
            </Typography>
            <Typography>{t(item.description)}</Typography>
          </Grid>
        ))}
      </Grid>

      <Typography mb={2}>
        {t("operating_manual_launching_exam_description4")}{" "}
        <Box
          component="img"
          src={recenter}
          alt="Description Image"
          sx={{
            width: 20,
            height: 20,
            verticalAlign: "middle",
            mx: 0.5,
            display: "inline-block",
          }}
        />{" "}
        {t("operating_manual_launching_exam_description4b")}
      </Typography>

      {!smallerThanSm && (
        <Grid
          container
          sx={{
            backgroundColor: "#F7F7F7",
            color: "rgba(0,0,0, 0.7)",
            textAlign: "center",
            py: 2,
            mb: 2,
          }}
        >
          <Grid item sm={4} />
          <Grid item sm={4}>
            {t("operating_manual_launching_exam_note1_1")}
          </Grid>
          <Grid item sm={4}>
            {t("operating_manual_launching_exam_note1_2")}
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4} display="flex" flexDirection="column">
          <Typography variant="h6" fontWeight="bold">
            5.
          </Typography>
          <Typography>
            {t("operating_manual_launching_exam_description5")}
          </Typography>
        </Grid>
        {[Image4_a, Image4_b].map((_, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box
              component="img"
              src={_}
              alt={`Image ${index + 1}`}
              sx={{
                width: "100%",
                maxWidth: "100%",
                borderRadius: 1,
                objectFit: "cover",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} display="flex" flexDirection="column">
          <Typography variant="h6" fontWeight="bold">
            6.
          </Typography>
          <Typography>
            {t("operating_manual_launching_exam_description6")}
          </Typography>
        </Grid>
        {[Image5_a, Image5_b].map((_, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box
              component="img"
              src={_}
              alt={`Image ${index + 1}`}
              sx={{
                width: "100%",
                maxWidth: "100%",
                borderRadius: 1,
                objectFit: "cover",
              }}
            />{" "}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LaunchingExam;
