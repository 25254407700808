import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import comment from "../../../../../assets/images/operating-manual/4/comment.png";
import download from "../../../../../assets/images/operating-manual/4/download.png";
import search from "../../../../../assets/images/operating-manual/4/search.png";
import link from "../../../../../assets/images/operating-manual/4/link.png";
import Image1 from "../../../../../assets/images/operating-manual/4/4.7a.png";
import Image2 from "../../../../../assets/images/operating-manual/4/4.7b.png";

// const DescriptionGridItem = ({ description, img }) => (
//   <Grid item xs={12} sm={6} display="flex" flexDirection="column" gap={1}>
//     <Typography variant="body1" color="text.secondary">
//       {description}
//     </Typography>
//     {img && (
//       <img
//         src={img}
//         alt="Description"
//         style={{
//           borderRadius: "8px",
//           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
//         }}
//       />
//     )}
//   </Grid>
// );

const ViewingResults = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box id="viewing-results" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="600">
          {`4.7. ${t("operating_manual_viewing_results_title")}`}
        </Typography>
      </Box>

      <Box sx={{ ...sectionContainerStyle, mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography sx={{ color: "text.primary", flex: 1 }}>
            {`1. ${t("operating_manual_viewing_results_description1")}`}
            <Box
              component="img"
              src={link}
              alt="Description Icon"
              sx={{
                width: 20,
                height: 20,
                verticalAlign: "middle",
                mx: 0.5,
                display: "inline-block",
              }}
            />
            {t("operating_manual_viewing_results_description1b")}
          </Typography>

          <Box
            component="img"
            src={Image1}
            alt="Description Image"
            sx={{
              width: "50%",
              height: "auto",
              ml: 2,
              float: "right",
              borderRadius: 1,
            }}
          />
        </Box>

        {/* <Grid container spacing={3}>
          
          <DescriptionGridItem
            description={`1. ${t("operating_manual_viewing_results_description1")}`}
          />
          <DescriptionGridItem img={null} /> {/* Placeholder for image */}
        {/* </Grid>  */}
        {/* 
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <DescriptionGridItem
            description={`2. ${t("operating_manual_viewing_results_description2")}`}
          />
          <DescriptionGridItem img={null} /> {/* Placeholder for image */}
        {/* </Grid>  */}

        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography sx={{ color: "text.primary", flex: 1 }}>
            {`2. ${t("operating_manual_viewing_results_description2")}`}
            <Box
              component="img"
              src={search}
              alt="Description Icon"
              sx={{
                width: 20,
                height: 20,
                verticalAlign: "middle",
                mx: 0.5,
                display: "inline-block",
              }}
            />
            {t("operating_manual_viewing_results_description2b")}
          </Typography>

          <Box
            component="img"
            src={Image2}
            alt="Description Image"
            sx={{
              width: "40%",
              height: "auto",
              ml: 2,
              float: "right",
              borderRadius: 1,
            }}
          />
        </Box>

        <Typography sx={{ mt: 1, color: "text.primary" }}>
          3.{" "}
          <span style={{ color: "#FB923C", fontWeight: "bold" }}>
            {t("operating_manual_viewing_results_description3_1")}
          </span>
          {t("operating_manual_viewing_results_description3_2")}{" "}
          <Box
            component="img"
            src={comment}
            alt="Description Image"
            sx={{
              width: 20,
              height: 20,
              verticalAlign: "middle",
              mx: 0.5,
              display: "inline-block",
            }}
          />{" "}
          {t("operating_manual_viewing_results_description3_3")}
        </Typography>

        <Typography mb={2}>
          {`4. ${t("operating_manual_viewing_results_description4")}`}
          <Box
            component="img"
            src={download}
            alt="Description Image"
            sx={{
              width: 70,
              height: 30,
              verticalAlign: "middle",
              mx: 0.5,
              display: "inline-block",
            }}
          />{" "}
          {t("operating_manual_viewing_results_description4_1")}
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "grey.100",
          color: "text.secondary",
          p: 2,
          mt: 3,
          borderRadius: 2,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        {t("operating_manual_viewing_results_note")}
      </Box>
    </Box>
  );
};

export default ViewingResults;
