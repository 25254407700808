import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";

const Security = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Typography variant="h5" fontWeight={"bold"}>
        8. Security
      </Typography>
      <Typography>
        All data is encrypted in transit and at rest with AES-512 protocol.
      </Typography>
    </Box>
  );
};

export default Security;
