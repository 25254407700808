import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const Results = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box id="results" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" gutterBottoma fontWeight={"bold"}>
          1.3 {t("operating_manual_result_title")}
        </Typography>
      </Box>
      <Typography>{t("operating_manual_result_description")}</Typography>
    </Box>
  );
};

export default Results;
