import { Box, Typography } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const Browser = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Typography variant="h5" fontWeight={"bold"}>
        7. Browser
      </Typography>
      <Typography>
        RVF100 webportal supports latest versions from Chrome, Edge, Safari and
        Firefox.
      </Typography>
    </Box>
  );
};

export default Browser;
