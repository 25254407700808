import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
const TestPatterns = () => {
  const { t } = useTranslation();
  const featureCount = 6;
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box id="test-patterns" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" gutterBottom fontWeight={"bold"}>
          3.2 {t("operating_manual_test_patterns_title")}
        </Typography>
      </Box>
      <div
        className="my-5 bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto"
        style={{ color: "#BD1A7D" }}
        id="test-patterns"
      >
        {[...Array(featureCount)].map((_, index) => (
          <div
            className="flex flex-col sm:flex-row items-start sm:items-center mb-4 last:mb-0 bg-white p-4 rounded-md shadow-sm"
            key={index}
          >
            <span className="py-1 px-4 text-pink-800 font-bold rounded-md sm:min-w-[150px] w-full sm:w-auto text-center sm:text-left mr-0 sm:mr-5 mb-2 sm:mb-0">
              {t(`operating_manual_test_patterns_header${index + 1}`)}
            </span>
            <div className="description flex-grow text-gray-700">
              {t(`operating_manual_test_patterns_content${index + 1}`)}
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default TestPatterns;
