import algoliasearch from "algoliasearch";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  connectStateResults,
  Hits,
  InstantSearch,
} from "react-instantsearch-dom";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const AlgoliaSearch = () => {
  const navigate = useNavigate();

  const [searchState, setSearchState] = useState({ query: "" });
  const [isInputFocused, setIsInputFocused] = useState(false);
  const searchContainerRef = useRef(null);

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      console.log(searchResults);
      const hasResults = searchResults && searchResults?.nbHits !== 0;

      return (
        <div>
          {hasResults ? (
            children
          ) : (
            <div className="w-full max-w-screen-xl text-center">
              <div className="shadow-md rounded-lg p-4 text-gray-700">
                <p className="font-medium">
                  No results found for "
                  <span className="font-bold text-gray-900">
                    {searchState.query}
                  </span>
                  "
                </p>
                <p className="text-sm text-gray-500">
                  Please try another search query.
                </p>
              </div>
            </div>
          )}
        </div>
      );
    }
  );

  const handleClick = useCallback(
    (hit) => {
      setIsInputFocused(false);
      const subPath =
        typeof hit?.path === "string"
          ? hit.path.trim().split("/").filter(Boolean).pop()
          : "";
      if (window.location.pathname.includes(hit?.path)) {
        if (hit?.type === "main-link") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const element = document.getElementById(subPath);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else {
        navigate(hit.path);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Hit = ({ hit }) => {
    return (
      <div
        className={styles.hitItem}
        onClick={() => {
          handleClick(hit);
        }}
        style={{ cursor: "pointer" }}
      >
        <h4>{hit.operating_manual_important_info_title}</h4>
        <p>{hit.operating_manual_important_info_description1}</p>
      </div>
    );
  };

  return (
    <div ref={searchContainerRef} className={styles.searchContainer}>
      <InstantSearch
        searchClient={searchClient}
        indexName="algolia"
        searchState={searchState}
        onSearchStateChange={(updatedSearchState) =>
          setSearchState(updatedSearchState)
        }
      >
        <div className="">
          <div className="w-full">
            <div
              className={`shadow-md rounded-lg transition-all duration-300 ${styles.searchBoxContainer}`}
            >
              <div
                className={`flex  rounded-lg overflow-hidden transition-all duration-300 ${
                  isInputFocused ? styles.inputFocused : ""
                }`}
              >
                <input
                  className={`${styles.searchInput} px-4 py-3 w-full outline-none transition-all duration-300`}
                  type="text"
                  onFocus={() => setIsInputFocused(true)}
                  onChange={(e) => setSearchState({ query: e.target.value })}
                  value={searchState.query}
                  placeholder="🔍 Search the docs..."
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.searchResultsContainer} ${
            isInputFocused ? styles.visible : styles.hidden
          }`}
        >
          <Results>
            <Hits hitComponent={Hit} />
          </Results>{" "}
        </div>
      </InstantSearch>
    </div>
  );
};

export default AlgoliaSearch;
