import { useRoutes } from "react-router-dom";

// Routes definitions
import routes from "../../../navigation/routes";

// Styles to be shared accross the app, such as css varibles
import "./index.module.css";
import LanguageSwitcher from "../../Language";

const App = () => {
  const content = useRoutes(routes);

  return (
    <div>
      <LanguageSwitcher />
      {content}
    </div>
  );
};

export default App;
