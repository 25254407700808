import { Navigate } from "react-router-dom";

// Page components
import OperationalManual from "../pages/OperatingManual";
import ImportantInfo from "../pages/OperatingManual/Contents/ImportantInfo";
import SystemDescription from "../pages/OperatingManual/Contents/SystemDescription";
import SoftwareUpdatesSpecifications from "../pages/OperatingManual/Contents/SoftwareUpdatesSpecifications";
import GettingStarted from "../pages/OperatingManual/Contents/GettingStarted";
import Features from "../pages/OperatingManual/Contents/GettingStarted/Features";
import TestPatterns from "../pages/OperatingManual/Contents/GettingStarted/TestPatterns";
import HeadMountedDeviceGuide from "../pages/OperatingManual/Contents/GettingStarted/HeadMountedDeviceGuide";
import PortalGuide from "../pages/OperatingManual/Contents/GettingStarted/PortalGuide";
import WarningsPrecautions from "../pages/OperatingManual/Contents/ImportantInfo/WarningsPrecautions";
import Results from "../pages/OperatingManual/Contents/SystemDescription/Results";
import Usage from "../pages/OperatingManual/Contents/SystemDescription/Usage";
import IntendedUse from "../pages/OperatingManual/Contents/SystemDescription/IntendedUse";
import InstructionsForUse from "../pages/OperatingManual/Contents/InstructionsForUse";
import AccountRegistration from "../pages/OperatingManual/Contents/InstructionsForUse/AccountRegistration";
import TurningOnVRDevice from "../pages/OperatingManual/Contents/InstructionsForUse/TurningOnVRDevice";
import CreatingExam from "../pages/OperatingManual/Contents/InstructionsForUse/CreatingExam";
import LaunchingExam from "../pages/OperatingManual/Contents/InstructionsForUse/LaunchingExam";
import ViewingResults from "../pages/OperatingManual/Contents/InstructionsForUse/ViewingResults";
import VirtualRealityDevice from "../pages/OperatingManual/Contents/VirtualRealityDevice";
import SetUpWiFi from "../pages/OperatingManual/Contents/VirtualRealityDevice/SetUpWiFi";
import VRDeviceCare from "../pages/OperatingManual/Contents/VirtualRealityDevice/VRDeviceCare";
import FAQ from "../pages/OperatingManual/Contents/FAQ";
import Browser from "../pages/OperatingManual/Contents/Browser";
import ContactUs from "../pages/OperatingManual/Contents/ContactUs";
import AppendixASymbolGlossary from "../pages/OperatingManual/Contents/AppendixASymbolGlossary";
import AppendixBPatientView from "../pages/OperatingManual/Contents/AppendixBPatientView";
import AppendixVisualField from "../pages/OperatingManual/Contents/AppendixBPatientView/AppendixVisualField";
import AppendixCPortalIconGlossary from "../pages/OperatingManual/Contents/AppendixCPortalIconGlossary";
import ClinicSetting from "../pages/OperatingManual/Contents/InstructionsForUse/ClinicSettings";
import CreatePatient from "../pages/OperatingManual/Contents/InstructionsForUse/CreatePatient";
import Security from "../pages/OperatingManual/Contents/Security";

const routes = [
  {
    path: "/",
    element: <Navigate to="/operating-manual" />,
  },
  {
    path: "operating-manual",
    element: <OperationalManual />,
    children: [
      {
        path: "",
        element: <Navigate to="important-information" />,
      },
      {
        path: "important-information",
        element: <ImportantInfo />,
        children: [
          {
            path: "warnings-and-precautions",
            element: <WarningsPrecautions />,
          },
        ],
      },
      {
        path: "system-description",
        element: <SystemDescription />,
        children: [
          {
            path: "intended-use",
            element: <IntendedUse />,
          },
          {
            path: "usage",
            element: <Usage />,
          },
          {
            path: "results",
            element: <Results />,
          },
        ],
      },
      {
        path: "software-updates",
        element: <SoftwareUpdatesSpecifications />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
        children: [
          {
            path: "features",
            element: <Features />,
          },
          {
            path: "test-patterns",
            element: <TestPatterns />,
          },
          {
            path: "head-mounted-device-guide",
            element: <HeadMountedDeviceGuide />,
          },
          {
            path: "portal-guide",
            element: <PortalGuide />,
          },
        ],
      },
      {
        path: "instructions-for-use",
        element: <InstructionsForUse />,
        children: [
          {
            path: "account-registration",
            element: <AccountRegistration />,
          },
          {
            path: "configure-clinic-settings",
            element: <ClinicSetting />,
          },
          {
            path: "turning-on-vr-device",
            element: <TurningOnVRDevice />,
          },
          {
            path: "create-select-patient",
            element: <CreatePatient />,
          },
          {
            path: "creating-exam",
            element: <CreatingExam />,
          },
          {
            path: "launching-exam",
            element: <LaunchingExam />,
          },
          {
            path: "viewing-results",
            element: <ViewingResults />,
          },
        ],
      },
      {
        path: "virtual-reality-device",
        element: <VirtualRealityDevice />,
        children: [
          {
            path: "set-up-wifi",
            element: <SetUpWiFi />,
          },
          {
            path: "vr-device-care",
            element: <VRDeviceCare />,
          },
        ],
      },
      {
        path: "faq",
        element: <FAQ />,
      },
      {
        path: "browser",
        element: <Browser />,
      },
      {
        path: "security",
        element: <Security />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "appendix-a-symbol-glossary",
        element: <AppendixASymbolGlossary />,
      },
      {
        path: "appendix-b-patient-view",
        element: <AppendixBPatientView />,
        children: [
          {
            path: "appendix-visual-field",
            element: <AppendixVisualField />,
          },
        ],
      },
      {
        path: "appendix-c-portal-icon-glossary",
        element: <AppendixCPortalIconGlossary />,
      },
    ],
  },
];

export default routes;
