import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Description2 from "../../../../../assets/images/operating-manual/4/description2.png";
import Description3 from "../../../../../assets/images/operating-manual/4/description3.png";
import PersonIcon from "@mui/icons-material/Person";

const ClinicSetting = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box
      id="configure-clinic-settings"
      sx={{ ...sectionContainerStyle, padding: 3 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.2. {t("operating_manual_configure_clinic_setting_title")}
        </Typography>
      </Box>

      <Box component="ol" sx={{ pl: 3 }}>
        <li>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {t("operating_manual_configure_clinic_setting_description1")}
            <PersonIcon sx={{ mr: 1, color: "primary.dark" }} />
          </Box>
        </li>
        <li>{t("operating_manual_configure_clinic_setting_description2")}</li>
        <Box
          component="img"
          src={Description2}
          alt="Description 2"
          sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
        />

        <li>
          {t("operating_manual_configure_clinic_setting_description3")}
          <Box
            component="img"
            src={Description3}
            alt="Description 3"
            sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
          />
        </li>
      </Box>
    </Box>
  );
};

export default ClinicSetting;
